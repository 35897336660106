import React, { useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MaintenancePage from './Body/MaintenancePage';
import Body from './Body/Body';
import Header from './Component/Header';
import ProductDetailsPage from './pages/ProductDetailsPage';
import AddProduct from './AdminDashBoard/AddProduct';
import Register from "./pages/Register";
import Cart from './pages/Cart';
import Login from './pages/Login';
import Footer from './Component/Footer';
import productContext from './Context/ProductContext/ProductContext';
import Profile from './pages/Profile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import ShippingAndDelivery from './pages/ShippingAndDelivery';
import CancellationAndRefund from './pages/CancellationAndRefund';
import AboutPage from './pages/AboutPage';
import ContactUs from './pages/ContactUs';
import Disclaimer from './pages/Disclaimer';
import Pagenotfound from './pages/Pagenotfound';
import PaymentOptions from './pages/PaymentOptions';
import BlogPage from './pages/BlogPage';
import NewsPage from './pages/NewsPage';
import PaymentConfirmationPage from './pages/PaymentConfirmationPage';
import GoToTop from './Component/GoToTop';
import SearchSari from './pages/SearchSari';
import HotSells from './pages/HotSells';
import NewProduct from './pages/NewProduct';
import OrdersPage from './pages/OrdersPage';
import ProductList from './DropDown/ProductList';
import AllProduct from './Body/AllProduct';

const App = () => {
  const { authinticated, setAuthinticated } = useContext(productContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthinticated(token);
    }
  }, [setAuthinticated]);

  return (
    <BrowserRouter>
      {/* <Header />
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/allProduct" element={<AllProduct />} />
        <Route path="/productDetails/:id" element={<ProductDetailsPage />} />
        <Route path="/addProduct" element={<AddProduct />} />
        <Route path="/cart" element={authinticated ? <Cart /> : <Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={authinticated ? <Profile /> : <Login />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/ShippingAndDelivery" element={<ShippingAndDelivery />} />
        <Route path="/CancellationandRefund" element={<CancellationAndRefund />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="*" element={<Pagenotfound />} />
        <Route path="/PaymentOptions" element={<PaymentOptions />} />
        <Route path="/blogpage" element={<BlogPage />} />
        <Route path="/newspage" element={<NewsPage />} />
        <Route path="/order" element={<OrdersPage/>} />
        <Route path="/payment-confirmation" element={<PaymentConfirmationPage />} />
        <Route path="/SearchSari" element={<SearchSari />} />
        <Route path="/HotSells" element={<HotSells />} />
        <Route path="/NewProduct" element={<NewProduct />} />
        <Route path="/dropdown/product-list/:catagory" element={<ProductList />} />
      </Routes>
      <Footer />
      <GoToTop /> */}
    
    {/* Maintenance Page */}
      <MaintenancePage />

    </BrowserRouter>
  );
};

export default App;
